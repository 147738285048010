import React from "react";

import { Article, Frame, Iframe, IframeArea } from "./style";
import FrameDevice from "./frame-bottom-device.webp";

export default function VideoIntroduction({ src, title }) {
  return (
    <Article>
      <IframeArea>
        <Iframe loading="lazy" src={src} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" />
      </IframeArea>
      <Frame alt="Pensamental - Computador" src={FrameDevice} />
    </Article>
  );
}
