import tw from "tailwind-styled-components";

export const Article = tw.article`
  w-auto
  h-auto
  inline-flex 
  flex-col
  items-center 
  justify-center 
`;

export const IframeArea = tw.div`
  relative
  rounded-lg
  border-2
  border-blue-pensamental-darkest
`;

export const Iframe = tw.iframe`
  w-frame sm:w-500 lg:w-685
  h-auto sm:h-350 lg:h-432

  rounded-lg
  border-5 md:border-18
  border-blue-pensamental-light md:border-black
`;

export const Frame = tw.img`
  relative
  w-full sm:w-900
  invisible sm:visible
`;
