/* eslint-disable node/no-unsupported-features/node-builtins */
import React from "react";
import Tracker from "@openreplay/tracker/cjs";

// images
import FormArticleBg from "../../images/form-article-bg.webp";

// components
import Main from "../../components/main";
import Header from "../../components/header";
import Seo from "../../components/seo";
import Footer from "../../components/footer";

// styles
import * as SC from "../../styles/formConfirmacao";
import { usePreventAccess } from "../../hooks/usePreventAccess";
import VideoFrame from "../../components/videoFrame";

// open replay
const tracker = new Tracker({ projectKey: "mJk2kHRhU2x0UP887beO", __DISABLE_SECURE_MODE: true });

// default
function FormConfirmacaoComponent({ location }) {
  const isValid = usePreventAccess(location);
  const isRunTime = typeof window !== "undefined";

  if (!isRunTime) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  React.useEffect(() => {
    if (isRunTime) {
      tracker.start();
    }
  }, []);

  return (
    <>
      <Seo title="| Confirmação Mentoria" />
      <Main className="z-30">
        <Header blueDefault />

        <SC.HeaderSpacer />

        <img src={FormArticleBg} alt="Pensamental - Fundo BG" style={{ display: "none" }} />

        <SC.ConfirmacaoArticle>
          <SC.ConfirmacaoWrapper isValid={!isValid}>
            <SC.ConfirmacaoTitle>{isValid ? "Parabéns por ter completado essa etapa!" : "Infelizmente você não pode acessar isto!"}</SC.ConfirmacaoTitle>

            <SC.ConfirmacaoLabelWrapper>
              <SC.ConfirmacaoLabel>
                {isValid
                  ? "Assista ao vídeo abaixo e aproveite o conteúdo bônus por ter finalizado o formulário. Em breve, entraremos em contato com você para te mostrar como trabalharemos juntos a sua solução!"
                  : "Preencha o formulário para obter o vídeo."}
              </SC.ConfirmacaoLabel>
            </SC.ConfirmacaoLabelWrapper>

            {isValid ? (
              <React.Suspense fallback={<div />}>
                <VideoFrame src="https://www.youtube.com/embed/zqI6NUHd9dY?rel=0&amp;controls=0" title="Conteúdo Bônus para Superar Desafios - Formulário Pensamental" />
              </React.Suspense>
            ) : (
              <SC.ButtonCTA target="_self" href="/form">
                IR AO FORMULÁRIO
              </SC.ButtonCTA>
            )}
          </SC.ConfirmacaoWrapper>
        </SC.ConfirmacaoArticle>

        <Footer hideFooter isDarkest />
      </Main>
    </>
  );
}

export default FormConfirmacaoComponent;
