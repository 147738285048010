/* eslint-disable node/no-unsupported-features/node-builtins */
/* eslint-disable import/prefer-default-export */

export function usePreventAccess(location) {
  try {
    const params = new URLSearchParams(location.search);
    const paramE = params.get("t");
    const decodedE = window.atob(paramE);
    const parsedE = JSON.parse(decodedE);

    const noExists = !paramE || paramE === null;
    if (noExists) {
      return false;
    }

    const isObject = typeof parsedE === "object";
    if (!isObject) {
      return false;
    }

    const hasExpired = new Date(parsedE.expiration) <= new Date();
    if (hasExpired) {
      return false;
    }

    return true;
  } catch {
    return false;
  }
}
