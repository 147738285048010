import React from "react";
import styled from "styled-components";
import tw from "tailwind-styled-components";

export const PatternBG = styled.article`
  background-image: url("/static/form-article-bg-0b04054470bc68327e04215c060d357d.webp");
`;

export const HeaderSpacer = styled.div`
  width: 100%;
  height: 75px;
  background-color: transparent;
`;

// confirmacao
export function ConfirmacaoArticle({ children }) {
  return <PatternBG className="w-full flex flex-col justify-center items-center p-6 bg-white">{children}</PatternBG>;
}

export function ConfirmacaoWrapper({ children, isValid, ...props }) {
  const extraClasses = isValid ? "justify-start" : "justify-center";
  return (
    <div {...props} className={`text-center w-full max-w-screen-md flex flex-col items-center ${extraClasses}`}>
      {children}
    </div>
  );
}

const ConfirmacaoTitleInner = styled.h2`
  line-height: 35px;
`;

export const ConfirmacaoTitle = tw(ConfirmacaoTitleInner)`
  mt-2 
  font-baloo
  text-4xl
  text-blue-pensamental-dark
  mb-4
  text-center
`;

export function ConfirmacaoLabelWrapper({ children }) {
  return <div className="w-full mb-4 flex justify-center items-center">{children}</div>;
}

export function ConfirmacaoLabel({ children }) {
  return <p className="max-w-screen-sm font-comfortaa text-gray-400 text-sm md:text-base">{children}</p>;
}

export const ConfirmacaoImage = styled.img`
  margin-bottom: -25px;
`;

export const ButtonCTA = tw.a`
  outline-none
  no-underline 
  text-white 
  inline-flex 
  items-center 
  justify-center 
  px-12 py-3
  font-baloo 
  rounded-md 
  bg-blue-pensamental-light 
  cursor-pointer
  transition duration-300 ease-in-out 
  transform 
  hover:bg-blue-pensamental-light
  hover:scale-110
  hover:text-white
  focus:ring-2 focus:ring-blue-pensamental-dark
`;
